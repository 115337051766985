import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const navbarStyles = {
    padding: "2.25em 0px",
  };

  const navbarContainerStyles = {
    margin: "auto",
  };

  const navbarFlexStyles = {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const navbarMenuStyles = {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
    alignItems: "center",
  };

  const navbarMenuLinkStyles = {
    marginLeft: "25px",
    fontStyle: "inherit",
  };

  const titleStyles = {
    fontFamily: "'Rock Salt', cursive",
    fontWeight: "bolder",
  };

  return (
    <nav style={navbarStyles}>
      <div style={navbarContainerStyles}>
        <div style={navbarFlexStyles}>
          <div>
            <Link style={titleStyles} to="/">
              Sam Udelman
            </Link>
          </div>
          <div style={navbarMenuStyles}>
            <Link to="/posts" style={navbarMenuLinkStyles}>
              Posts
            </Link>
            <Link to="/projects" style={navbarMenuLinkStyles}>
              Projects
            </Link>
          </div>
        </div>
        <p>
          Operations Manager at{" "}
          <a
            href="https://www.ccell.co.uk"
            target="_blank"
            rel="noreferrer noopener"
          >
            CCell Renewables
          </a>
        </p>
      </div>
    </nav>
  );
};

export default Navbar;
