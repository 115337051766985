import React from "react";

const Projects = () => {
  return (
    <div className="projects">
      <p>Coming soon...</p>
    </div>
  );
};

export default Projects;
