import React from "react";

const homeStyles = {};
const Home = () => {
  return (
    <div>
      <h2>First post coming soon</h2>
    </div>
  );
};

export default Home;
