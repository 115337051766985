import React from "react";

const Posts = () => {
  return (
    <div className="posts">
      <p>Coming soon...</p>
    </div>
  );
};

export default Posts;
