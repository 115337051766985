import React from "react";
import { useResizeDetector } from "react-resize-detector";
import { NARROW_WINDOW_WIDTH } from "../etc/constants";

const Footer = () => {
  const year = new Date().getFullYear();
  const { width, height, ref } = useResizeDetector();

  const footerContainerStyles =
    width > NARROW_WINDOW_WIDTH
      ? {}
      : {
          marginTop: "2em",
          borderTop: "0.05em solid black",
          paddingTop: "2em",
          borderColor: "gray",
        };

  const footerStyles =
    width > NARROW_WINDOW_WIDTH
      ? {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }
      : {
          textAlign: "center",
        };

  const dividerStyles = {
    flexGrow: 1,
    borderBottom: "0.2em solid black",
    margin: "3em",
  };

  const socialMediaLinkStyles = {
    color: "black",
    fontSize: "1.5em",
    marginTop: width > NARROW_WINDOW_WIDTH ? 0 : "1em",
  };

  const twitterIconStyles = {
    display: "inline-block",
    height: "1em",
    width: "1em",
    fill: "currentcolor",
    userSelect: "none",
    verticalAlign: "text-top",
  };

  return (
    <div ref={ref} style={footerContainerStyles}>
      <footer style={footerStyles}>
        <div>
          Copyright © <strong>Sam Udelman</strong>, {year}
        </div>

        {width > 500 && <span style={dividerStyles}></span>}

        <div style={socialMediaLinkStyles}>
          <a
            aria-label="Twitter"
            name="Twitter"
            href="https://twitter.com/sbudelman"
            style={socialMediaLinkStyles}
          >
            <svg
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 20 20"
              style={twitterIconStyles}
            >
              <path d="M17.316,6.246c0.008,0.162,0.011,0.326,0.011,0.488c0,4.99-3.797,10.742-10.74,10.742c-2.133,0-4.116-0.625-5.787-1.697c0.296,0.035,0.596,0.053,0.9,0.053c1.77,0,3.397-0.604,4.688-1.615c-1.651-0.031-3.046-1.121-3.526-2.621c0.23,0.043,0.467,0.066,0.71,0.066c0.345,0,0.679-0.045,0.995-0.131c-1.727-0.348-3.028-1.873-3.028-3.703c0-0.016,0-0.031,0-0.047c0.509,0.283,1.092,0.453,1.71,0.473c-1.013-0.678-1.68-1.832-1.68-3.143c0-0.691,0.186-1.34,0.512-1.898C3.942,5.498,6.725,7,9.862,7.158C9.798,6.881,9.765,6.594,9.765,6.297c0-2.084,1.689-3.773,3.774-3.773c1.086,0,2.067,0.457,2.756,1.191c0.859-0.17,1.667-0.484,2.397-0.916c-0.282,0.881-0.881,1.621-1.66,2.088c0.764-0.092,1.49-0.293,2.168-0.594C18.694,5.051,18.054,5.715,17.316,6.246z"></path>
            </svg>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
