import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./footer";
import Navbar from "./navbar";
import Home from "./home";
import Posts from "./posts";
import Projects from "./projects";

const App = () => {
  return (
    <Router>
      <div className="app">
        <Navbar />

        <Switch>
          <Route path="/posts">
            <Posts />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>

        <Footer />
      </div>
    </Router>
  );
};

export default App;
